<template>
  <div id="ai-services_aiu_image-object-detection">
    <service-template
      :run="run"
      :run-disabled="currentExample === null && uploadedImage === null"
      :run-success="runSuccess"
      :run-error="runError"
      :loading="loading"
      :icon="require('@/assets/images/icons/services/header/OBJ.svg')"
      type="image"
      service="aiu"
    >
      <template v-slot:header>
        {{ $t('services.imageObjectDetection.header') }}
      </template>
      <template v-slot:left>
        <carousel :step="1">
          <el-image
            v-for="(example, index) in examples"
            :key="index"
            :slot="`slide-${index}`"
            :class="{ 'swiper-selected': currentExample === index }"
            :src="example"
            fit="cover"
            class="example"
            @click="() => onExampleClick(index)"
          />
        </carousel>
        <upload
          :auth="isAuthenticated()"
          :file-size="2"
          :example-file="examples[currentExample]"
          :disabled="loading"
          :handle-remove="onUploadRemove"
          :handle-upload="onUploadSuccess"
        />
      </template>
      <template v-slot:right>
        <generated-result
          :step="2"
          :service-name="$t('services.imageObjectDetection.header')"
          :loading="loading"
          :subtitle-extra="$t(`button['Check the full list of objects']`)"
          :subtitle-extra-click="showFullListOfObjects"
        >
          <template v-if="result">
            <div class="result-description">
              <!-- eslint-disable vue/no-v-html -->
              <div
                class="totals-description"
                v-html="$t('services.imageObjectDetection.totalsDescription', [result.num_detections || 0])"
              />
            </div>
            <image-with-marks
              class="result"
              :src="imageSrc"
              :marks="imageMarks"
              :image-with-marks-src.sync="imageWithMarksSrc"
            />
            <div class="download-image">
              <download-image :src="imageWithMarksSrc" />
            </div>
          </template>
        </generated-result>
      </template>
    </service-template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import ImageWithMarks from '@/views/pages/AIServices/components/ImageWithMarks/Index.vue';
import DownloadImage from '@/components/DownloadImage/Index.vue';
import Carousel from '@/components/Carousel/Index.vue';
import Upload from '@/components/Upload/Index.vue';

const convertPc = (val) => Number(val) * 100;

export default {
  name: 'ImageObjectDetection',
  components: {
    DownloadImage,
    Upload,
    Carousel,
    GeneratedResult,
    ServiceTemplate,
    ImageWithMarks,
  },
  data: () => ({
    loading: false,
    result: null,
    currentExample: null,
    uploadedImage: null,
    uploadedImageBase64: null,
    imageWithMarksSrc: '',
  }),
  computed: {
    ...mapGetters(['isAuthenticated']),
    imageSrc() {
      const {
        uploadedImage,
        uploadedImageBase64,
        examples,
        currentExample,
      } = this;

      return uploadedImage ? `data:${uploadedImage.type};base64, ${uploadedImageBase64}` : examples[currentExample];
    },
    imageMarks() {
      const { colorsPool } = this.$consts.aiuObjectClassColor;
      const classColor = {};
      let nextColorIndex = 0;

      const suggestClassColor = (className) => {
        if (classColor[className] === undefined) {
          // eslint-disable-next-line no-plusplus
          classColor[className] = nextColorIndex++ % colorsPool.length;
        }

        return colorsPool[classColor[className]];
      };

      return (this.result?.objects_detected || []).map((item) => ({
        class: item.class,
        color: this.$consts.aiuObjectClassColor[item.class] || suggestClassColor(item.class),
        name: item.class.replace(/_/g, ' '),
        score: convertPc(item.class_score),
        position: [
          [item.position[1], item.position[0]].map(convertPc),
          [item.position[3], item.position[2]].map(convertPc),
        ],
      }));
    },
    examples() {
      return [
        `${this.$consts.publicPath}/examples/AIU/OBJ/bike.jpg`,
        `${this.$consts.publicPath}/examples/AIU/OBJ/bus_and_people.jpg`,
        `${this.$consts.publicPath}/examples/AIU/OBJ/cars_on_highway.jpg`,
        `${this.$consts.publicPath}/examples/AIU/OBJ/dinner_set.jpg`,
        `${this.$consts.publicPath}/examples/AIU/OBJ/donuts.jpg`,
        `${this.$consts.publicPath}/examples/AIU/OBJ/people_boats.jpg`,
        `${this.$consts.publicPath}/examples/AIU/OBJ/people_tennis.jpg`,
      ];
    },
  },
  watch: {
    async uploadedImage(uploadedImage) {
      if (uploadedImage) {
        this.uploadedImageBase64 = this.$utils.arrayBufferToBase64(await uploadedImage.arrayBuffer());
      } else {
        this.uploadedImageBase64 = null;
      }
    },
  },
  methods: {
    async run() {
      this.loading = true;
      const formData = new FormData();
      if (this.uploadedImage) {
        formData.append('file', this.uploadedImage);
      } else {
        const imageResponse = await this.$api.aiu.fetchImage(
          this.examples[this.currentExample],
        );
        formData.append('file', imageResponse.data);
      }

      return this.$api.aiu.imageObjectDetection.detectImageObjects(formData);
    },
    runSuccess(result) {
      this.loading = false;
      this.result = result.data;
    },
    runError() {
      this.loading = false;
    },
    onExampleClick(index) {
      if (this.loading) {
        return;
      }

      this.currentExample = index;
      this.uploadedImage = null;
      this.result = null;
    },
    onUploadRemove() {
      this.result = null;
      this.currentExample = null;
      this.uploadedImage = null;
    },
    async onUploadSuccess(file) {
      this.result = null;
      this.uploadedImage = file;
    },
    showFullListOfObjects() {
      const routeData = this.$router.resolve({ path: '/labels/image-object-detection' });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
<style lang='scss'>
#ai-services_aiu_image-object-detection {
  .result-description {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .totals-description {
    font-size: 0.18rem;
    line-height: 0.22rem;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    text-align: left;
    margin-bottom: 0.28rem;

    > span {
      color: #1BC117;
    }
  }
  .download-image {
    text-align: right;
  }
}
</style>

<style lang='scss' scoped>
#ai-services_aiu_image-object-detection {
  background: $background-secondary;

  .result {
    width: 100%;
  }

  .totals-description {
    font-size: 0.18rem;
    line-height: 0.22rem;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    text-align: center;
  }

  .example {
    height: 100%;
    cursor: pointer;
  }
}
</style>
