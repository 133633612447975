<template>
  <div class="image-with-marks">
    <div>
      <el-image
        :src="src"
        :preview-src-list="[imageSrc]"
        :z-index="9001"
        fit="cover"
      />
      <div
        v-for="(mark, index) in marks"
        :key="index"
        class="mark"
        :style="{
          left: `${mark.position[0][0]}%`,
          top: `${mark.position[0][1]}%`,
          width: `${mark.position[1][0]-mark.position[0][0]}%`,
          height: `${mark.position[1][1]-mark.position[0][1]}%`,
          borderColor: mark.color || '#82F70B'
        }"
      >
        <span
          class="mark-text"
          :style="{ background: mark.color || '#82F70B' }"
        >
          {{ mark.name }}: <strong>{{ Math.round(mark.score) }}%</strong>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-mixed-operators, no-param-reassign */

const font = '14px Montserrat';
const borderWidth = 1;
const wPadding = 5;
const hPadding = 5;

const drawTextBG = (ctx, txt, fillStyle, x, y) => {
  ctx.save();

  ctx.font = font;
  ctx.textBaseline = 'top';
  ctx.fillStyle = fillStyle;

  const width = ctx.measureText(txt).width + wPadding * 2;
  const height = parseInt(font, 10) + 2 * hPadding;

  ctx.fillRect(x - borderWidth, y - height + borderWidth, width + borderWidth, height);
  ctx.fillStyle = '#000';
  ctx.fillText(txt, x + wPadding, y - height + hPadding + borderWidth);
  ctx.restore();
};

export default {
  name: 'ImageWithMarks',
  props: {
    src: {
      type: String,
      required: true,
    },
    marks: {
      type: Array,
      required: true,
    },
    imageWithMarksSrc: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    imageSrc: null,
  }),
  async beforeMount() {
    await this.syncCanvas();
  },
  methods: {
    async syncCanvas() {
      const canvas = await this.$utils.imageToCanvas(this.src);
      const ctx = canvas.getContext('2d');

      this.marks.forEach((mark) => {
        const x = mark.position[0][0] * canvas.width / 100;
        const y = mark.position[0][1] * canvas.height / 100;
        const w = (mark.position[1][0] - mark.position[0][0]) * canvas.width / 100;
        const h = (mark.position[1][1] - mark.position[0][1]) * canvas.height / 100;
        const color = mark.color || '#82F70B';

        ctx.beginPath();
        ctx.lineWidth = 2;
        ctx.strokeStyle = color;
        ctx.rect(x, y, w, h);
        ctx.stroke();

        drawTextBG(ctx, `${this.$utils.capitalize(mark.name)}: ${Math.round(mark.score)}%`, color, x, y);
      });

      canvas.toBlob((blob) => {
        this.imageSrc = URL.createObjectURL(blob);
        this.$emit('update:imageWithMarksSrc', this.imageSrc);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$mark-border-width: 2px;

.image-with-marks {
  display: inline-block;
  position: relative;

  > div {
    display: inline-block;
    position: relative;

    > img {
      width: 100%;
    }
  }

  .mark {
    pointer-events: none;
    position: absolute;
    border: $mark-border-width solid red;
    box-sizing: border-box;
  }

  .mark-text {
    position: absolute;
    z-index: 1;
    white-space: nowrap;
    font-family: Montserrat, sans-serif;
    font-size: 0.12rem;
    font-weight: 500;
    padding: 0.02rem 0.04rem;
    text-transform: capitalize;
    color: #000;
    transform: translateY(-100%);
    left: -$mark-border-width;
  }
}
</style>
