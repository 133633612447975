var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-with-marks"},[_c('div',[_c('el-image',{attrs:{"src":_vm.src,"preview-src-list":[_vm.imageSrc],"z-index":9001,"fit":"cover"}}),_vm._l((_vm.marks),function(mark,index){return _c('div',{key:index,staticClass:"mark",style:({
        left: `${mark.position[0][0]}%`,
        top: `${mark.position[0][1]}%`,
        width: `${mark.position[1][0]-mark.position[0][0]}%`,
        height: `${mark.position[1][1]-mark.position[0][1]}%`,
        borderColor: mark.color || '#82F70B'
      })},[_c('span',{staticClass:"mark-text",style:({ background: mark.color || '#82F70B' })},[_vm._v(" "+_vm._s(mark.name)+": "),_c('strong',[_vm._v(_vm._s(Math.round(mark.score))+"%")])])])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }